import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`2-Push Jerks`}</p>
    <p>{`+`}</p>
    <p>{`1-Split Jerk`}</p>
    <p><em parentName="p">{`*`}{`compare to 10/16/20`}</em></p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`10-S2OH (135/95)(RX+ 155/105)`}</p>
    <p>{`15-Pullups (RX+ CTB)`}</p>
    <p>{`20-Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      